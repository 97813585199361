import * as echarts from 'echarts'
import { isToFixed } from '../index'

export default (data = [], typeIndex) => {
  const xAxisData = []
  const levelCount1 = []
  const levelCount2 = []
  const levelCount3 = []
  const levelCount4 = []
  // data.map(item => {
  //   xAxisData.push(item.name)
  //   levelCount1.push(item.levelCount1)
  //   levelCount2.push(item.levelCount2)
  //   levelCount3.push(item.levelCount3)
  //   levelCount4.push(item.levelCount4)
  // })

  typeIndex.map(i => {
    const name = data[i].name.replaceAll('阿米巴', '')
    xAxisData.push(name)
    levelCount1.push(isToFixed(data[i].levelCount1, 1))
    levelCount2.push(isToFixed(data[i].levelCount2, 1))
    levelCount3.push(isToFixed(data[i].levelCount3, 1))
    levelCount4.push(isToFixed(data[i].levelCount4, 1))
  })
  return {
    grid: {
      top: '20px',
      left: '10%',
      right: '2%',
      bottom: '60px',
      containLabel: true
    },
    legend: {
      bottom: '5px',
      textStyle: {
        color: '#fff',
        fontSize: 12
      },
      itemWidth: 22, // 设置宽度
      itemHeight: 16, // 设置高度
      itemGap: 12 // 设置间距
    },
    tooltip: {
      trigger: 'axis',
      formatter: p => {
        let div = ''
        p.map((item, index) => {
          if (index === 0) {
            div += `${item.name}
              <br />
              ${item.seriesName}:${item.value}
            `
          } else {
            div += `<br />
            ${item.seriesName}:${item.value}
          `
          }
        })
        return div
      }
    },
    // dataZoom: [
    //   {
    //     // id: 'dataZoomX',
    //     show: false,
    //     height: 6,
    //     // xAxisIndex: [0],
    //     bottom: 0,
    //     start: 0,
    //     end: 20,
    //     throttle: 200,
    //     zoomLock: true,
    //     zoomOnMouseWheel: false,
    //     handleIcon:
    //       'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
    //     handleSize: '110%',
    //     handleStyle: {
    //       color: '#d3dee5'
    //     },
    //     textStyle: {
    //       color: 'transparent'
    //     },
    //     borderColor: '#90979c'
    //   },
    //   {
    //     type: 'inside',
    //     show: false,
    //     height: 6,
    //     start: 1,
    //     end: 35
    //   }
    // ],
    xAxis: [
      {
        data: xAxisData,
        axisLabel: {
          margin: 10,
          color: '#fff',
          interval: 0,
          rotate: 0,
          textStyle: {
            fontSize: 12
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#fff'
          }
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: {
            color: '#fff'
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        minInterval: 1,
        axisLabel: {
          color: '#fff',
          textStyle: {
            fontSize: 12
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#102E74'
          }
        }
      }
    ],
    series: [
      {
        name: '轻微',
        type: 'bar',
        data: levelCount1,
        stack: 'one',
        barWidth: '15px',
        itemStyle: {
          // normal: {
          //   show: false,
          //   color: new echarts.graphic.LinearGradient(
          //     0,
          //     0,
          //     0,
          //     1,
          //     [
          //       {
          //         offset: 0,
                  color: '#4489f9' // 0% 处的颜色
          //       },
          //       {
          //         offset: 1,
          //         color: 'transparent' // 100% 处的颜色
          //       }
          //     ],
          //     false
          //   ),
          //   barBorderRadius: [30, 30, 0, 0]
          // }
        },
        label: {
          normal: {
            show: false,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        }
      },
      {
        name: '一般',
        type: 'bar',
        data: levelCount2,
        stack: 'one',
        barWidth: '15px',
        itemStyle: {
          // normal: {
          //   show: false,
          //   color: new echarts.graphic.LinearGradient(
          //     0,
          //     0,
          //     0,
          //     1,
          //     [
          //       {
          //         offset: 0,
                  color: '#1fcb30' // 0% 处的颜色
          //       },
          //       {
          //         offset: 1,
          //         color: 'transparent' // 100% 处的颜色
          //       }
          //     ],
          //     false
          //   ),
          //   barBorderRadius: [30, 30, 0, 0]
          // }
        },
        label: {
          normal: {
            show: false,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        }
      },
      {
        name: '严重',
        type: 'bar',
        data: levelCount3,
        stack: 'one',
        barWidth: '15px',
        itemStyle: {
          // normal: {
          //   show: false,
          //   color: new echarts.graphic.LinearGradient(
          //     0,
          //     0,
          //     0,
          //     1,
          //     [
          //       {
          //         offset: 0,
                  color: '#f2b537' // 0% 处的颜色
          //       },
          //       {
          //         offset: 1,
          //         color: 'transparent' // 100% 处的颜色
          //       }
          //     ],
          //     false
          //   ),
          //   barBorderRadius: [30, 30, 0, 0]
          // }
        },
        label: {
          normal: {
            show: false,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        }
      },
      {
        name: '灾难',
        type: 'bar',
        data: levelCount4,
        stack: 'one',
        barWidth: '15px',
        itemStyle: {
          // normal: {
          //   color: new echarts.graphic.LinearGradient(
          //     0,
          //     0,
          //     0,
          //     1,
          //     [
          //       {
          //         offset: 0,
                  color: '#f7795a' // 0% 处的颜色
          //       },
          //       {
          //         offset: 1,
          //         color: 'transparent' // 100% 处的颜色
          //       }
          //     ],
          //     false
          //   ),
          //   barBorderRadius: [30, 30, 0, 0]
          // }
        },
        label: {
          normal: {
            show: false,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        }
      }
    ]
  }
}
