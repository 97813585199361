import * as echarts from 'echarts'
import { isToFixed } from '../index'

export default (data = [], typeIndex) => {
  const xAxisData = []
  const seriesData = []

  typeIndex.map(i => {
    const name = data[i].name.replaceAll('阿米巴', '')
    xAxisData.push(name)
    seriesData.push(isToFixed(data[i].amount, 1))
  })
  return {
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      formatter: p => {
        let div = `
          ${p[0].name}:
          ${p[0].value}
        `
        return div
      }
    },
    title: {
      show: true,
      text: '（万）',
      textStyle: {
        fontSize: 12,
        color: '#fff'
      },
      y: 30,
      x: 5,
      padding: 0,
      itemGap: 0
    },
    // dataZoom: [
    //   {
    //     // show: xAxisData.length > 5,
    //     show: false,
    //     height: 6,
    //     xAxisIndex: [0],
    //     bottom: 0,
    //     start: 1,
    //     end: 50,
    //     handleIcon:
    //       'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
    //     handleSize: '110%',
    //     handleStyle: {
    //       color: '#d3dee5'
    //     },
    //     textStyle: {
    //       color: 'transparent'
    //     },
    //     borderColor: '#90979c'
    //   },
    //   {
    //     type: 'inside',
    //     show: false,
    //     height: 6,
    //     start: 1,
    //     end: 35
    //   }
    // ],
    xAxis: [
      {
        axisLine: {
          show: true,
          lineStyle: {
            color: '#fff'
          }
        },
        axisLabel: {
          textStyle: {
            fontSize: '12'
          }
        },
        type: 'category',
        data: xAxisData
      }
    ],
    yAxis: [
      {
        minInterval: 1,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#fff'
          }
        },
        axisLabel: {
          textStyle: {
            fontSize: '10'
          }
        },
        interval: Math.ceil(340),
        splitLine: {
          show: false
        },
        type: 'value'
      }
    ],
    resize: {
      width: '100%',
      height: '100%'
    },
    series: [
      {
        name: 'Line 1',
        type: 'line',
        stack: '总量',
        smooth: true,
        lineStyle: {
          width: 1
        },
        label: {
          normal: {
            show: true,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        },
        showSymbol: true,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(11, 168, 181)'
            },
            {
              offset: 0.5,
              color: 'rgba(43, 102, 181)'
            },
            {
              offset: 1,
              color: 'rgba(43, 102, 181, 0.2)'
            }
          ])
        },
        data: seriesData
      }
    ]
  }
}
