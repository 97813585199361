<template>
  <div class="view-box-item" :style="isBlue ? viewBoxBgB : viewBoxBg">
    <div class="view-box-item-header" :class="{ b: isBlue }">{{ value }}</div>
    <div class="view-box-item-footer">{{ title }}</div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import boxItemR from '@assets/image/boxItemR.png'
import boxItemRB from '@assets/image/boxItemB.png'

export default {
  name: 'ViewBoxItem',
  props: {
    title: {
      type: String,
      default: '广东省'
    },
    value: {
      type: [String, Number],
      default: 20
    },
    isBlue: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const viewBoxBg = reactive({
      background: `url('${boxItemR}') no-repeat`
    })
    const viewBoxBgB = reactive({
      background: `url('${boxItemRB}') no-repeat`
    })
    return {
      viewBoxBg,
      viewBoxBgB
    }
  }
}
</script>

<style lang="scss" scoped>
.view-box-item {
  min-width: 136px;
  // width: 100%;
  // height: 100%;
  min-height: 40px;
  // flex: 1;
  box-sizing: border-box;
  // border: 8px solid transparent;
  margin: 5px 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
  background-size: 100% 100% !important;
  &-header {
    font-size: 30px;
    color: #e6004d;
  }
  &-header.b {
    color: #93b2ff;
  }
  &-footer {
    color: #fff;
  }
}
</style>
