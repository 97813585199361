import mapJson from '../map.json'
import gdJson from '../gdJson.json'
import * as echarts from 'echarts'
// const colorArr = ['#a6c84c', '#41cff9', '#ffa022']
const geoCoordMap = {
  广州市: [113.507649675, 23.3200491021],
  东莞市: [113.863433991, 22.9430238154],
  中山市: [113.422060021, 22.5451775145],
  云浮市: [111.750945959, 22.9379756855],
  佛山市: [113.034025635, 23.0350948405],
  惠州市: [114.41065808, 23.1135398524],
  揭阳市: [116.079500855, 23.3479994669],
  梅州市: [116.126403098, 24.304570606],
  汕头市: [116.588650288, 23.2839084533],
  汕尾市: [115.572924289, 22.9787305002],
  江门市: [112.678125341, 22.2751167835],
  河源市: [114.913721476, 23.9572508505],
  深圳市: [114.025973657, 22.5960535462],
  清远市: [113.040773349, 23.9984685504],
  湛江市: [110.165067263, 21.2574631038],
  潮州市: [116.830075991, 23.7618116765],
  珠海市: [113.262447026, 22.1369146461],
  肇庆市: [112.37965337, 23.5786632829],
  茂名市: [110.931245331, 21.9682257188],
  阳江市: [111.777009756, 21.9715173045],
  韶关市: [113.594461107, 24.8029603119]
}

echarts.registerMap('china', mapJson)
echarts.registerMap('guangdong', gdJson)
export default (data = [], index = 0, mapIndex = 0) => {
  // const max = 6000
  // const min = 10
  // const maxSize4Pin = 100
  // const minSize4Pin = 20
  const points = []
  let mapName = mapIndex === 0 ? 'china' : 'guangdong'
  const mapCityNameColor = mapIndex === 0 ? 'transparent' : '#41d2f9'
  const gd = []
  const qt = []
  data.map(item => {
    if (geoCoordMap[item.city]) {
      gd.push({ ...item })
    } else {
      qt.push({ ...item })
    }
  })
  const nData = mapIndex === 0 ? [...qt, ...gd] : gd
  nData.map((item, i) => {
    // index = index + 1 >= colorArr.length ? 0 : index
    const {
      longitude,
      latitude,
      city,
      companyName,
      companyId,
      userTotal,
      completeTotal,
      deviceTotal,
      errorTotal,
      projectTotal
    } = item

    // if (index === i) {
    //   if (geoCoordMap[city]) {
    //     console.log(city)
    //     mapName = 'guangdong'
    //   } else {
    //     mapName = 'china'
    //   }
    // }

    points.push({
      name: city,
      value: [longitude || 0, latitude || 0],
      itemStyle: {
        color: index === i ? '#ffa022' : '#41cff9'
      },
      city,
      companyName: companyName.replaceAll('阿米巴', ''),
      companyId,
      userTotal,
      completeTotal,
      deviceTotal,
      errorTotal,
      projectTotal,
      label: {
        show: index === i
      }
    })
  })
  return {
    // tooltip: {
    //   trigger: 'item',
    //   padding: 0,
    //   enterable: true,
    //   transitionDuration: 1,
    //   textStyle: {
    //     color: '#000',
    //     decoration: 'none'
    //   },
    //   formatter: params => {
    //     if (params.seriesType == 'effectScatter') {
    //       return `城市：${params.data.city} 阿米巴：${params.data.companyName}`
    //     } else {
    //       return params.name
    //     }
    //   }
    // },
    geo: {
      map: mapName,
      // map: 'china',
      aspectScale: 0.75, //长宽比
      zoom: 1.2,
      scaleLimit: {
        min: 1,
        max: 3
      },
      roam: false,
      itemStyle: {
        normal: {
          areaColor: {
            type: 'radial',
            x: 0.5,
            y: 0.5,
            r: 0.8,
            colorStops: [
              {
                offset: 0,
                color: '#09132c' // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#274d68' // 100% 处的颜色
              }
            ],
            globalCoord: true // 缺省为 false
          },
          shadowColor: '#a9c9ff',
          shadowOffsetX: 5,
          shadowOffsetY: 5
        },
        emphasis: {
          areaColor: '#2AB8FF',
          borderWidth: 0,
          color: 'green',
          label: {
            show: false
          }
        }
      }
      // regions: [
      // {
      //   name: '南海诸岛',
      //   itemStyle: {
      //     areaColor: 'rgba(0, 10, 52, 1)',
      //     borderColor: 'rgba(0, 10, 52, 1)',
      //     normal: {
      //       opacity: 0,
      //       label: {
      //         show: false,
      //         color: '#009cc9'
      //       }
      //     }
      //   }
      // }
      // ]
    },
    series: [
      {
        type: 'map',
        roam: false,
        label: {
          normal: {
            show: mapIndex === 1,
            textStyle: {
              color: mapCityNameColor
            }
          },
          emphasis: {
            textStyle: {
              color: '#41d2f9'
            }
          }
        },

        itemStyle: {
          normal: {
            borderColor: '#196173',
            borderWidth: 1,
            areaColor: {
              type: 'radial',
              x: 0.5,
              y: 0.5,
              r: 0.8,
              colorStops: [
                {
                  offset: 0,
                  color: '#1f273f' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#1f273f' // 100% 处的颜色
                }
              ],
              globalCoord: true // 缺省为 false
            }
          },
          emphasis: {
            areaColor: '#1f273f',
            // shadowColor: 'rgb(12,25,50)',
            borderWidth: 0.1
          }
        },
        zoom: 1.2,
        //     roam: false,
        // map: 'china' //使用
        map: mapName //使用
        // data: this.difficultData //热力图数据   不同区域 不同的底色
      },
      {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        showEffectOn: 'render',
        zlevel: 1,
        rippleEffect: {
          period: 15,
          scale: 4,
          brushType: 'stroke'
        },
        hoverAnimation: false,
        label: {
          normal: {
            formatter: function (p) {
              const {
                companyName,
                city,
                userTotal = 0,
                completeTotal = 0,
                deviceTotal = 0,
                errorTotal = 0,
                projectTotal = 0
              } = p.data

              return `{div|阿米巴:  ${companyName} }\n{div|客户服务数量:  ${projectTotal} }\n{div|所属城市:  ${city} }\n{div|运维人数:  ${userTotal} }\n{div|总维护设备:  ${deviceTotal} }\n{div|故障总数:  ${errorTotal} }\n{div|已处理故障总数:  ${completeTotal} }`
            },
            rich: {
              div: {
                fontFamily: 'Microsoft YaHei',
                color: '#fff',
                fontSize: 14,
                lineHeight: 24,
                width: '40px',
                wordBreak: 'break-all'
              }
            },
            position: 'right',
            offset: [10, 10],
            // position: 'top',
            // offset: [0, -10],
            color: '#fff',
            padding: [10, 10, 10, 10],
            borderRadius: 5,
            backgroundColor: '#283557',
            fontSize: 14,
            show: true
          }
        },
        // itemStyle: {
        //   normal: {
        //     color: '#1DE9B6',
        //     /* function (value){ //随机颜色
        //                      return "#"+("00000"+((Math.random()*16777215+0.5)>>0).toString(16)).slice(-6);
        //                      }*/
        //     shadowBlur: 0,
        //     shadowColor: '#283557'
        //   }
        // },
        symbolSize: 12,
        // symbol: 'circle',
        // symbolSize: val => {
        //   const a = (maxSize4Pin - minSize4Pin) / (max - min)
        //   const b = maxSize4Pin - a * max
        //   return a * val[1] + b * 1.2
        // },
        data: points
      }, //地图线的动画效果
      {
        type: 'lines',
        zlevel: 2,
        effect: {
          show: true,
          period: 4, //箭头指向速度，值越小速度越快
          trailLength: 0.4, //特效尾迹长度[0,1]值越大，尾迹越长重
          symbol: 'arrow', //箭头图标
          symbolSize: 5 //图标大小
        },
        lineStyle: {
          normal: {
            color: '#1DE9B6',
            /* function (value){ //随机颜色
                        
                        ['#f21347','#f3243e','#f33736','#f34131','#f34e2b',
                        '#f56321','#f56f1c','#f58414','#f58f0e','#f5a305',
                        '#e7ab0b','#dfae10','#d5b314','#c1bb1f','#b9be23',
                        '#a6c62c','#96cc34','#89d23b','#7ed741','#77d64c',
                        '#71d162','#6bcc75','#65c78b','#5fc2a0','#5abead',
                        '#52b9c7','#4fb6d2','#4ab2e5']
 return "#"+("00000"+((Math.random()*16777215+0.5)>>0).toString(16)).slice(-6);
 }*/
            width: 1, //线条宽度
            opacity: 0.1, //尾迹线条透明度
            curveness: 0.3 //尾迹线条曲直度
          }
        },
        // 动画线
        data: [
          //   {
          //     coords: [
          //       [117.4219, 39.4189],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#f58414'
          //     }
          //   },
          //   {
          //     coords: [
          //       [112.3352, 37.9413],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#f58f0e'
          //     }
          //   },
          //   {
          //     coords: [
          //       [103.5901, 36.3043],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#e7ab0b'
          //     }
          //   },
          //   {
          //     coords: [
          //       [106.3586, 38.1775],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#dfae10'
          //     }
          //   },
          //   {
          //     coords: [
          //       [103.9526, 30.7617],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#c1bb1f'
          //     }
          //   },
          //   {
          //     coords: [
          //       [108.37, 22.82],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#c1bb1f'
          //     }
          //   },
          //   {
          //     coords: [
          //       [113.23, 23.16],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#c1bb1f'
          //     }
          //   },
          //   {
          //     coords: [
          //       [107.13, 34.37],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#c1bb1f'
          //     }
          //   },
          //   {
          //     coords: [
          //       [109.48, 36.6],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#c1bb1f'
          //     }
          //   },
          //   {
          //     coords: [
          //       [113.62, 34.75],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#c1bb1f'
          //     }
          //   },
          //   {
          //     coords: [
          //       [105.7314, 34.5874],
          //       [109.1162, 34.2004]
          //     ],
          //     lineStyle: {
          //       color: '#c1bb1f'
          //     }
          //   }
        ]
      }
    ]
  }
}
