<template>
  <div class="view-box-item-count" :style="viewBoxBgB">
    <div class="view-box-item-count-header">
      <span>{{ title[0] }}</span> <span class="value">{{ value[0] }}</span>
    </div>
    <div class="view-box-item-count-footer">
      <div>
        <span>{{ title[1] }}</span> <span class="value">{{ value[1] }}</span>
      </div>
      <div>
        <span>{{ title[2] }}</span>
        <span class="value" :class="{ r: !isBlue }">{{ value[2] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import boxItemRB from '@assets/image/boxItemB.png'

export default {
  name: 'ViewBoxItemCount',
  props: {
    title: {
      type: Array,
      default: () => ['广东省', '广东省', '广东省']
    },
    value: {
      type: Array,
      default: () => [0, 0, 0]
    },
    isBlue: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const viewBoxBgB = reactive({
      background: `url('${boxItemRB}') no-repeat`
    })
    return {
      viewBoxBgB
    }
  }
}
</script>

<style lang="scss" scoped>
.view-box-item-count {
  min-width: 160px;
  // width: 100%;
  // height: 100%;
  min-height: 50px;
  // box-sizing: border-box;
  // border: 8px solid transparent;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  background-size: 100% 100% !important;
  &-header {
    font-size: 12px;
    text-align: left;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
    color: #fff;
    .value {
      font-size: 18px;
      margin-left: 10px;
      color: #93b2ff;
      vertical-align: middle;
    }
  }
  &-header .r {
    color: #e6004d;
  }
  &-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 12px;
    color: #fff;
    > div {
      display: flex;
      flex-direction: column;
    }
    .value {
      font-size: 18px;
      // margin-left: 10px;
      color: #93b2ff;
      vertical-align: middle;
      text-align: center;
    }
  }
  &-footer .r {
    color: #e6004d;
  }
}
</style>
