<template>
  <div class="view-box" :style="viewBoxBg">
    <div class="view-box-header" v-if="showHeader">
      <slot name="icon"> <i class="el-icon-s-home"> </i></slot>
      <span>
        <span class="view-box-header-line">|</span>
        <span class="view-box-header-title">{{ title }}</span>
        <span>{{ describe }}</span>
      </span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { reactive } from 'vue'
import frameBg from '@assets/image/bottom-frame@3x(4).png'
import frameBgYy from '@assets/image/bottom-frame-yy@2x(2).png'

export default {
  name: 'ViewBox',
  props: {
    border: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    background: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '满意度'
    },
    describe: {
      type: String,
      default: 'Degree of satisfaction'
    }
  },
  setup(props) {
    const viewBoxBg = reactive({
      borderImage: props.border ? `url('${frameBg}') 25 25 stretch` : '',
      background: props.background ? `url('${frameBgYy}')` : '',
      backgroundColor: props.backgroundColor ? '#1f273f' : ''
    })
    return {
      viewBoxBg
    }
  }
}
</script>

<style lang="scss" scoped>
.view-box {
  width: 100%;
  height: 98%;
  box-sizing: border-box;
  border: 8px solid transparent;
  position: relative;
  &-header {
    color: #93b2ff;
    position: absolute;
    top: 0;
    left: 0;
    > i {
      font-size: 16px;
      margin-right: 5px;
      vertical-align: text-bottom;
    }
    > span {
      font-size: 10px;
      font-weight: normal;
    }
    &-title {
      font-size: 14px;
      margin: 0 5px;
    }
    &-line {
      font-size: 14px;
      margin: 0 5px;
      vertical-align: text-bottom;
    }
  }
}
</style>
