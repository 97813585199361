import * as echarts from 'echarts'
import { isToFixed } from '../index'

export default (data, typeIndex) => {
  // 4
  const labels = []
  const thisScores = []
  const lastScores = []
  typeIndex.map(i => {
    const nlabels = data.labels[i].replaceAll('阿米巴', '')
    labels.push(nlabels)
    const nThisScores = isToFixed(data.thisScores[i], 1)
    thisScores.push(nThisScores)
    const nLastScores = isToFixed(data.lastScores[i], 1)
    lastScores.push(nLastScores)
  })

  // 月份
  // const month = new Date().getMonth()

  return {
    grid: {
      top: '20%',
      left: '10%',
      right: '2%',
      bottom: '30px'
    },
    tooltip: {
      trigger: 'axis',
      formatter: p => {
        let div = ''
        p.map((item, index) => {
          if (index === 0) {
            div += `${item.name}
              <br />
              ${item.seriesName}:${item.value}
            `
          } else {
            div += `<br />
            ${item.seriesName}:${item.value}
          `
          }
        })
        return div
      }
    },
    legend: {
      // data: ['本月', '上月'],
      right: '3%',
      top: '4%',
      textStyle: {
        color: '#fff',
        fontSize: 12
      },
      itemWidth: 22, // 设置宽度
      itemHeight: 16, // 设置高度
      itemGap: 12 // 设置间距
    },
    // dataZoom: [
    //   {
    //     // id: 'dataZoomX',
    //     // show: labels.length > 5,
    //     show: false,
    //     height: 6,
    //     // xAxisIndex: [0],
    //     bottom: 0,
    //     start: 0,
    //     end: 20,
    //     throttle: 200,
    //     zoomLock: true,
    //     zoomOnMouseWheel: false,
    //     handleIcon:
    //       'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
    //     handleSize: '110%',
    //     handleStyle: {
    //       color: '#d3dee5'
    //     },
    //     textStyle: {
    //       color: 'transparent'
    //     },
    //     borderColor: '#90979c'
    //   },
    //   {
    //     type: 'inside',
    //     show: false,
    //     height: 6,
    //     start: 1,
    //     end: 35
    //   }
    // ],
    xAxis: [
      {
        data: labels,
        axisLabel: {
          margin: 10,
          color: '#fff',
          interval: 0,
          rotate: 0,
          textStyle: {
            fontSize: 12
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#fff'
          }
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: {
            color: '#fff'
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        minInterval: 1,
        axisLabel: {
          color: '#fff',
          textStyle: {
            fontSize: 12
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#102E74'
          }
        }
      }
    ],
    series: [
      {
        name: (data.month)+'月份',
        type: 'bar',
        data: lastScores,
        barGap:1,
        barWidth: '12px',
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: '#f2b537' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'transparent' // 100% 处的颜色
                }
              ],
              false
            ),
            // barBorderRadius: [30, 30, 0, 0]
          }
        },
        label: {
          normal: {
            show: true,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        }
      },
      {
        name: data.lastMonth+'月份',
        type: 'bar',
        data: thisScores,
        barGap:1,
        barWidth: '12px',
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: '#1fcb30' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'transparent' // 100% 处的颜色
                }
              ],
              false
            ),
            // barBorderRadius: [30, 30, 0, 0]
          }
        },
        label: {
          normal: {
            show: true,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        }
      }
    ]
  }
}
