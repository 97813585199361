const Name = ['已完成', '未完成']
import { isToFixed } from '../index'

export default (data = [], typeIndex) => {
  // 1
  const dataOne = []
  const dataTow = []
  const xAxisData = []
  // data.map(item => {
  //   xAxisData.push(item.name)
  //   dataOne.push(item.completeCount)
  //   dataTow.push(item.undoErrorCount)
  // })

  typeIndex.map(i => {
    const name = data[i].name.replaceAll('阿米巴', '')
    xAxisData.push(name)
    dataOne.push(isToFixed(data[i].completeCount, 1))
    dataTow.push(isToFixed(data[i].undoErrorCount, 1))
  })

  return {
    title: {
      text: '（条）',
      x: '10',
      y: '10',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12,
        color: '#fff'
      }
    },
    grid: {
      top: '30%',
      left: '10%',
      right: '2%',
      bottom: '30px'
    },
    tooltip: {
      show: 'true',
      trigger: 'axis',
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        shadowStyle: {
          color: 'rgba(112,112,112,0)'
        }
      },
      formatter: params => {
        let div = ''
        params.map((item, index) => {
          if (index === 0) {
            div += `${item.name}`
          }

          if (['已完成', '未完成'].includes(item.seriesName)) {
            div += `<br />${item.seriesName}:${item.data}`
          }
        })
        return div
      },
      padding: [8, 10], //内边距
      extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);' //添加阴影
    },
    legend: {
      top: 20,
      right: 20,
      textStyle: {
        color: '#fff'
      },
      data: Name
    },
    // dataZoom: [
    //   {
    //     // id: 'dataZoomX',
    //     show: false,
    //     height: 6,
    //     // xAxisIndex: [0],
    //     bottom: 0,
    //     start: 60,
    //     end: 80,
    //     throttle: 200,
    //     zoomLock: true,
    //     zoomOnMouseWheel: false,
    //     handleIcon:
    //       'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
    //     handleSize: '110%',
    //     handleStyle: {
    //       color: '#d3dee5'
    //     },
    //     textStyle: {
    //       color: 'transparent'
    //     },
    //     borderColor: '#90979c'
    //   },
    //   {
    //     type: 'inside',
    //     show: false,
    //     height: 6,
    //     start: 1,
    //     end: 35
    //   }
    // ],
    xAxis: [
      {
        type: 'category',
        axisTick: {
          show: false
        },
        min: 0,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#fff'
          }
        },
        axisLabel: {
          inside: false,
          interval: 0,
          rotate: 0,
          textStyle: {
            color: '#fff',
            fontWeight: 'normal',
            fontSize: '12'
          }
        },
        data: xAxisData
      },
      {
        type: 'category',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitArea: {
          show: false
        },
        splitLine: {
          show: false
        },
        data: xAxisData
      },
      {
        type: 'category',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitArea: {
          show: false
        },
        splitLine: {
          show: false
        },
        data: xAxisData
      }
    ],
    yAxis: {
      type: 'value',
      minInterval: 1,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#32346c'
        }
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: '#32346c '
        }
      },
      axisLabel: {
        textStyle: {
          color: '#bac0c0',
          fontWeight: 'normal',
          fontSize: '12'
        }
      }
    },
    series: [
      {
        // name: '已完成', //这个是Bar图
        type: 'bar',
        barWidth: 10,
        label: {
          normal: {
            show: false,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff',
            distance: 0,
            backgroundColor: 'transparent',
            padding: 0,
            // borderRadius: 50,
            offset: [-10, 0]
          }
        },
        itemStyle: {
          normal: {
            color: 'transparent'
          }
        },
        data: dataOne
      },
      {
        name: '已完成', //这个是Bar图
        type: 'bar',
        barWidth: 10,
        barGap: '220%',
        xAxisIndex: 1,
        label: {
          show: false,
          formatter: '{a|}',
          color: '#fff',
          position: 'top',
          distance: -10,
          backgroundColor: '#04f1ff',
          padding: 2,
          // borderRadius: 15,
          rich: {
            a: {
              width: 6,
              height: 6,
              borderRadius: 6,
              lineHeight: 6,
              backgroundColor: '#fff'
            }
          }
          // normal: {
          //   show: true,
          //   position: 'top',
          //   formatter: e => {
          //     return e.value === 0 ? '' : e.value
          //   },
          //   fontSize: 12,
          //   color: '#fff'
          //   // offset: [4, -5]
          // }
        },
        itemStyle: {
          normal: {
            // barBorderRadius: [4, 4, 0, 0],
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#04f1ff' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#415ffe' // 100% 处的颜色
                }
              ]
            }
          }
        },
        data: dataOne
      },
      {
        // name: '未完成', //这个是Bar图
        type: 'bar',
        barWidth: 10,
        label: {
          normal: {
            show: false,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff',
            distance: 0,
            backgroundColor: 'transparent',
            padding: 0,
            // borderRadius: 50,
            offset: [10, 0]
          }
        },
        itemStyle: {
          normal: {
            color: 'transparent'
          }
        },
        data: dataTow
      },
      {
        name: '未完成', //这个是Bar图
        type: 'bar',
        barWidth: 10,
        xAxisIndex: 1,
        label: {
          show: false,
          formatter: '{a|}',
          color: '#fff',
          position: 'top',
          distance: -10,
          backgroundColor: '#ffd506',
          padding: 2,
          // borderRadius: 15,
          rich: {
            a: {
              width: 6,
              height: 6,
              borderRadius: 6,
              lineHeight: 6,
              backgroundColor: '#fff'
            }
          }
          // normal: {
          //   show: true,
          //   position: 'top',
          //   formatter: e => {
          //     return e.value === 0 ? '' : e.value
          //   },
          //   fontSize: 12,
          //   color: '#fff'
          //   // offset: [4, -5]
          // }
        },
        itemStyle: {
          normal: {
            // barBorderRadius: [4, 4, 0, 0],
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#ffd506' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#ff6651' // 100% 处的颜色
                }
              ]
            }
          }
        },
        data: dataTow
      }
    ]
  }
}
