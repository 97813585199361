<template>
  <div class="echarts-view">
    <!-- 导航提示标题 -->
    <!-- <ml-tip :showHeader="false" content="超级管理员" /> -->
    <!-- 视频 -->
    <ml-tip class="e-chart-box" :showHeader="false" style="border: 0">
      <el-scrollbar style="height: 100%" height="100%">
        <div
          class="e-chart-box-column"
          :style="{
            ...bgBStyle,
            minHeight: `${tabberHeight(isFull ? 35 : 85)}px`
          }"
        >
          <div class="left-view">
            <!-- <div>
              <view-box title="绩效收益" describe="A survey of Amoeba">
                <template #icon>
                  <el-image :src="gkIconImg" style="width: 14px; height: 14px" />
                </template>
                <general-situation
                  height="200px"
                  :data="fetchNewQuarterlyEarningsData"
                  style="padding-top: 25px"
                />
              </view-box>
            </div> -->
            <div>
              <view-box title="实时公告" describe="Real-time notices">
                <template #icon>
                  <el-image :src="gkIconImg" style="width: 14px; height: 14px" />
                </template>
                <div class="right-view-first-body-btn-div left-view-notices">
                  <div class="notice-notice" v-if="newCompanyNoticeRecordData['content']">
                    <vue-seamless-scroll
                      :data="newCompanyNoticeRecordData['content']"
                      :class-option="{
                        limitMoveNum: 1,
                        step: 0.4,
                        hoverStop: true
                      }"
                    >
                      <div :style="{ minHeight: '160px' }">
                        <div :style="{ textAlign: 'center', margin: '20px 0' }">
                          {{ newCompanyNoticeRecordData['title'] }}
                        </div>
                        <div v-html="newCompanyNoticeRecordData['content'].toString()">
                        </div>
                        <!-- {{ newCompanyNoticeRecordData['content'].toString() }} -->
<!--                        <div class="text-indent:2em;">-->
<!--                           {{ newCompanyNoticeRecordData['content'].toString() }}-->
<!--                        </div>-->

                      </div>
                    </vue-seamless-scroll>
                  </div>
                </div>
              </view-box>
            </div>
            <div>
              <view-box>
                <template #icon>
                  <el-image :src="jxIconImg" style="width: 14px; height: 14px" />
                </template>
                <ml-echarts
                  id="a2"
                  :option="z4Option(sfactionStatisticsData, sfactionStatisticsDataIndex)"
                />
              </view-box>
            </div>
            <div>
              <view-box title="绩效排名" describe="Assessment score">
                <template #icon>
                  <el-image :src="pmImg" style="width: 14px; height: 14px" />
                </template>
                <ml-echarts
                  id="ec"
                  :option="
                    testOption(quarterlyEarningStatisticsData, quarterlyEarningStatisticsIndex)
                  "
                />
              </view-box>
            </div>
          </div>
          <div class="center-view">
            <div class="big-title-box" :style="{ backgroundImage: `url(${bigTitleBoxImg})` }">
              智慧运维指挥中心
            </div>
            <div :style="{ lineHeight: '25px' }">
              <div class="right-view-first-count center-view-count">
                <div>
                  <span :style="{ color: '#03D58B' }" @click="goProjectList">
                    {{ projectTotalStatisticsData.total }}
                    <!-- {{ projectTotalStatisticsData.projectTotal }} /
                    {{ projectTotalStatisticsData.total }} -->
                  </span>
                  <span @click="goProjectList">服务客户</span>
                </div>
                <div>
                  <span :style="{ color: '#FF884E' }" @click="goEquipmentManageList">
                    {{ projectTotalStatisticsData.deviceTotal }}
                  </span>
                  <span @click="goEquipmentManageList">运维设备</span>
                </div>
                <div>
                  <span :style="{ color: '#D4BB0C' }" @click="goProjectList">
                    {{ projectTotalStatisticsData.userTotal }}
                  </span>
                  <span @click="goProjectList">运维人员</span>
                </div>
              </div>
            </div>
            <div>
              <view-box
                :showHeader="false"
                :border="false"
                :backgroundColor="false"
                :background="false"
                :style="{ height: '100%' }"
              >
                <div class="map-view">
                  <div class="map-view-body">
                    <ml-echarts
                      id="ce"
                      @click-map="clickMap"
                      v-if="companyInfosData.length > 0 && mapIndex === 0"
                      :option="mOption(companyInfosData, companyInfosDataIndex, mapIndex)"
                    />
                    <ml-echarts
                      id="ce1"
                      @click-map="clickMap"
                      v-if="companyInfosData.length > 0 && mapIndex === 1"
                      :option="mOption(companyInfosData, companyInfosDataIndex, mapIndex)"
                    />
                  </div>
                  <div class="map-view-btn">
                    <div
                      :style="[mapIndex === 0 ? mapBtnActiveStyle : mapBtnStyle]"
                      @click="mapIndex = 0"
                    >
                      全国
                    </div>
                    <div
                      :style="[mapIndex === 1 ? mapBtnActiveStyle : mapBtnStyle]"
                      @click="mapIndex = 1"
                    >
                      广东
                    </div>
                  </div>
                </div>
              </view-box>
            </div>
            <div>
              <view-box
                :border="false"
                :backgroundColor="false"
                :background="false"
                title="待处理故障"
                describe="Pending faults"
              >
                <template #icon>
                  <el-image :src="timeImg" style="width: 14px; height: 14px" />
                </template>
                <div style="margin-top: 30px; height: 80px; overflow: hidden">
                  <vue-seamless-scroll
                    :data="errorCountStatisticsListData"
                    :class-option="{
                      limitMoveNum: 1,
                      step: 0.4,
                      hoverStop: true
                    }"
                    :style="{ height: '80px' }"
                  >
                    <div class="center-view-item" :style="{ marginBottom: '70px' }">
                      <view-box-item
                        v-for="item in errorCountStatisticsListData"
                        :key="item"
                        :isBlue="item.undoErrorCount === 0"
                        :title="item.name.replaceAll('阿米巴', '')"
                        :value="item.undoErrorCount"
                      />
                    </div>
                  </vue-seamless-scroll>
                </div>
              </view-box>
            </div>
          </div>
          <div class="right-view">
            <div>
              <view-box title="故障统计" describe="Alarm and fault">
                <template #icon>
                  <el-image :src="gjImg" style="width: 14px; height: 14px" />
                </template>
                <div class="right-view-first">
                  <div class="right-view-first-count">
                    <div>
                      <span>人工上报</span>
                      <span>{{ errorAlarmCountStatisticsData['alarmCount'] }}</span>
                    </div>
                    <div>
                      <span>自动上报</span>
                      <span>{{ errorAlarmCountStatisticsData['errorCount'] }}</span>
                    </div>
                    <div>
                      <span>已处理故障</span>
                      <span>{{ errorAlarmCountStatisticsData['completeCount'] }}</span>
                    </div>
                  </div>
                  <div class="right-view-first-body" style="height: 200px; padding: 0 10%">
                    <ml-echarts
                      id="acc"
                      :option="
                        z5Option(errorLevelCountStatisticsData, errorLevelCountStatisticsIndex)
                      "
                    />
                  </div>
                  <div class="right-view-first-body-btn">
                    <!-- <view-box-item-btn
                      :isSelect="tabIndex === '0'"
                      @on-btn="tabIndex = '0'"
                      title="告警与故障"
                    /> -->
                    <!-- <view-box-item-btn
                      :isSelect="tabIndex === '1'"
                      @on-btn="tabIndex = '1'"
                      title="实时公告"
                    /> -->
                  </div>
                  <div class="right-view-first-body">
                    <div class="right-view-first-body-btn-div" v-if="tabIndex === '0'">
                      <general-situation
                        :data="deviceErrorRecordsData"
                        :showTitle="false"
                        height="180px"
                        marginBottom="50px"
                        :header="deviceErrorRecordsDataHeared"
                        @on-load="onLoad"
                      />
                    </div>
                    <!-- <div class="right-view-first-body-btn-div" v-else>
                      <div class="notice-notice">
                        <vue-seamless-scroll
                          :data="newCompanyNoticeRecordData['content']"
                          :class-option="{ step: 0.4 }"
                        >
                          <div :style="{ textAlign: 'center', margin: '20px 0' }">
                            {{ newCompanyNoticeRecordData['title'] }}
                          </div>
                          <div>
                            {{ newCompanyNoticeRecordData['content'] }}
                          </div>
                        </vue-seamless-scroll>
                      </div>
                    </div> -->
                  </div>
                </div>
              </view-box>
            </div>
            <div>
              <view-box title="故障工单" describe="Fault work order">
                <template #icon>
                  <el-image :src="gdImg" style="width: 14px; height: 14px" />
                </template>
                <div class="right-view-last-countbox">
                  <div class="right-view-last-count">
                    <view-box-item-count
                      :title="errorCountStatisticsReactive.one"
                      :value="errorCountStatisticsData[0]"
                    />
                    <view-box-item-count
                      :title="errorCountStatisticsReactive.tow"
                      :isBlue="false"
                      :value="errorCountStatisticsData[1]"
                    />
                  </div>
                  <div style="flex: 1">
                    <ml-echarts
                      id="cj"
                      :option="
                        z1Option(errorCountStatisticsListData, errorCountStatisticsListIndex)
                      "
                    />
                  </div>
                </div>
              </view-box>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </ml-tip>
  </div>
</template>

<script>
import { inject, onMounted, onUnmounted, reactive, ref } from 'vue'
import { tabberHeight, getTabberData, animaction } from '@utils'
import ViewBox from '@components/viewBox.vue'
import ViewBoxItem from '@components/viewBoxItem.vue'
import ViewBoxItemCount from '@components/viewBoxItemcount.vue'
// import ViewBoxItemBtn from '@components/viewBoxItemBtn.vue'
import GeneralSituation from '@components/generalSituation.vue'
import bigTitleBoxImg from '../../assets/image/big-title-box@3x.png'
import testOption from '@utils/viewData/test'
import z4Option from '@utils/viewData/z4'
import z5Option from '@utils/viewData/z5'
import mOption from '@utils/viewData/m'
import z1Option from '@utils/viewData/z1'
import bgB from '@assets/image/bgB@3x.png'
// 图标
import gdImg from '@assets/image/gd.png'
import gjImg from '@assets/image/gj.png'
import gkIconImg from '@assets/image/gk-icon.png'
import jxIconImg from '@assets/image/jx-icon.png'
import pmImg from '@assets/image/pm.png'
import timeImg from '@assets/image/time.png'
import mapBtn from '@assets/image/mapBtn.png'
import mapBtnActive from '@assets/image/mapBtn-active.png'
import { useStore } from 'vuex'
import vueSeamlessScroll from 'vue-seamless-scroll/src'
import { useRouter } from 'vue-router'

export default {
  name: 'SuperAdminView',
  components: {
    ViewBox,
    ViewBoxItem,
    GeneralSituation,
    // ViewBoxItemBtn,
    ViewBoxItemCount,
    vueSeamlessScroll
  },
  setup() {
    const isFull = inject('isFull')
    const router = useRouter()
    const { commit, dispatch } = useStore()
    const bgBStyle = {
      background: `url(${bgB}) no-repeat `,
      backgroundSize: '100% 100%'
    }
    const tabIndex = ref('0')
    // const setTabIndex = () => {
    //   setTimeout(() => {
    //     tabIndex.value = tabIndex.value === '0' ? '1' : '0'
    //   }, 15000)
    // }
    // watch(
    //   () => tabIndex.value,
    //   () => {
    //     setTabIndex()
    //   }
    // )
    /* 开始 数据图表 **********************************/
    // 满意度统计
    const sfactionStatisticsData = ref({})
    const sfactionStatisticsDataIndex = ref([])
    const sfactionStatisticsDataNsetInterval = ref()
    const getSatisfactionStatistics = async () => {
      const { data } = await getTabberData(dispatch, 'fetchmonthEvaluateStatistics')
      sfactionStatisticsData.value = data || {}
      sfactionStatisticsDataNsetInterval.value = animaction(
        data.labels,
        sfactionStatisticsDataIndex
      )
    }
    // 满意度统计
    const fetchNewQuarterlyEarningsData = ref([])
    const getNewQuarterlyEarnings = async () => {
      const { data } = await getTabberData(dispatch, 'fetchNewQuarterlyEarnings')
      let nData = []
      if (data.length > 0) {
        nData = data.map(item => {
          item.companyName = item.companyName.replaceAll('阿米巴', '')
          return item
        })
      }
      fetchNewQuarterlyEarningsData.value = [...nData]
    }
    // 满意度统计
    const quarterlyEarningStatisticsData = ref([])
    const quarterlyEarningStatisticsIndex = ref([])
    const quarterlyEarningStatisticsInterval = ref()
    const getQuarterlyEarningStatistics = async () => {
      const { data } = await getTabberData(dispatch, 'fetchQuarterlyEarningStatistics')
      quarterlyEarningStatisticsData.value = data || []
      quarterlyEarningStatisticsInterval.value = animaction(data, quarterlyEarningStatisticsIndex)
    }
    // 满意度统计
    const errorCountStatisticsListData = ref([])
    const errorCountStatisticsListIndex = ref([])
    const errorCountStatisticsListInterval = ref()
    const getErrorCountStatisticsList = async () => {
      const { data } = await getTabberData(dispatch, 'fetchErrorCountStatisticsList')
      errorCountStatisticsListData.value = data || []
      errorCountStatisticsListInterval.value = animaction(data, errorCountStatisticsListIndex)
    }
    // 单位
    const companyInfosData = ref([])
    const companyInfosDataIndex = ref(0)
    const nsetInterval = ref()
    const getCompanyInfos = async () => {
      const { data } = await getTabberData(dispatch, 'fetchCompanyStatisticsList')
      let index = -1
      nsetInterval.value = setInterval(() => {
        companyInfosData.value = data
        index++
        companyInfosDataIndex.value = index
        if (index >= data.length - 1) {
          index = -1
        }
      }, 10000)
    }

    const clickMap = params => {
      if (params.componentSubType === 'effectScatter') {
        commit('setmapLabelData', params.data)
        router.push({ path: '/monitoringLayer' })
      }
    }
    // 项目设备人员数量统计
    const goProjectList = () => {
      router.push({ path: '/projectInfoManage' })
    }
    const goEquipmentManageList = () => {
      router.push({ path: '/equipmentManage' })
    }
    const projectTotalStatisticsData = reactive({
      deviceTotal: 0,
      projectTotal: 0,
      total: 0,
      userTotal: 0
    })
    const getProjectTotalStatistics = () => {
      dispatch('fetchGetProjectTotalStatistics').then(data => {
        if (data && data.code === 200) {
          const { deviceTotal, projectTotal, total, userTotal } = data.data || {}
          projectTotalStatisticsData.deviceTotal = deviceTotal || 0
          projectTotalStatisticsData.projectTotal = projectTotal || 0
          projectTotalStatisticsData.total = total || 0
          projectTotalStatisticsData.userTotal = userTotal || 0
        }
      })
    }

    // 地图切换
    const mapIndex = ref(1)
    const mapBtnStyle = reactive({
      background: `url(${mapBtn}) no-repeat`
    })
    const mapBtnActiveStyle = reactive({
      background: `url(${mapBtnActive}) no-repeat`
    })

    // 故障告警数量统计
    const errorAlarmCountStatisticsData = ref({})
    const getErrorAlarmCountStatistics = async () => {
      const { data } = await getTabberData(dispatch, 'fetchErrorAlarmCountStatistics')
      errorAlarmCountStatisticsData.value = data || {}
    }
    // 故障程度数量统计
    const errorLevelCountStatisticsData = ref([])
    const errorLevelCountStatisticsIndex = ref([])
    const errorLevelCountStatisticsInterval = ref()
    const getErrorLevelCountStatistics = async () => {
      const { data } = await getTabberData(dispatch, 'fetchErrorLevelCountStatistics')
      errorLevelCountStatisticsData.value = data || []
      errorLevelCountStatisticsInterval.value = animaction(data, errorLevelCountStatisticsIndex)
    }
    // 故障程度数量统计
    const newCompanyNoticeRecordData = reactive({
      title: '',
      content: []
    })
    const newCompanyNoticeRecord = async () => {
      const { data } = await getTabberData(dispatch, 'fetchNewCompanyNoticeRecord')
      let { title, content = '' } = data || {}
      if(content){
        content = content.replaceAll(' ','&nbsp;')
      }
      newCompanyNoticeRecordData.title = title || ''
      newCompanyNoticeRecordData.content = [content || '']
    }
    // 故障程度数量统计F
    const errorCountStatisticsData = ref([[], []])
    const errorCountStatisticsReactive = reactive({
      one: ['今日新增', '本月累计', '本月已完成'],
      tow: ['总累计', '今年已完成', '待完成'],
      valueOne: [],
      valueTow: []
    })
    const errorCountStatistics = async () => {
      const { data } = await getTabberData(dispatch, 'fetchErrorCountStatistics')
      if (data) {
        const {
          allCount,
          errorCount,
          monthCompleteCount,
          monthCount,
          todayCount,
          yearCompleteCount
        } = data
        errorCountStatisticsData.value = [
          [todayCount || 0, monthCount || 0, monthCompleteCount || 0],
          [allCount || 0, yearCompleteCount || 0, errorCount || 0]
        ]
      }
    }

    // 获取列表
    const pageIndex = ref(1)
    const isLoad = ref(false)
    const deviceErrorRecordsData = ref([])
    const deviceErrorRecordsDataHeared = [
      { name: '阿米巴 ', prop: 'companyName' },
      { name: '告警等级 ', prop: 'severity' },
      { name: '告警内容 ', prop: 'title' },
      { name: '发生时间 ', prop: 'submitTime' }
    ]
    const getFaultServiceCenterTabberData = async () =>
      // params = { pageIndex: pageIndex.value, pageSize: 10 }
      {
        // params = {}
        const { data } = await getTabberData(dispatch, 'fetchNewErrorRecordByCompany')
        if (data.length > 0) {
          isLoad.value = true
          isLoad.value = false
          const nData = data.map(item => {
            return {
              companyName: item.companyName.replaceAll('阿米巴', ''),
              severity: item.severity,
              title: item.title,
              submitTime: item.submitTime.split(' ')[0]
            }
          })
          deviceErrorRecordsData.value = nData
          // if (pageIndex.value <= 1) {
          //   deviceErrorRecordsData.value = nData
          // } else {
          //   deviceErrorRecordsData.value = [...deviceErrorRecordsData.value, ...nData]
          // }
        } else {
          isLoad.value = false
        }
      }
    // 滚动加载
    const onLoad = () => {
      if (isLoad.value) {
        pageIndex.value++
        getFaultServiceCenterTabberData({ pageIndex: pageIndex.value, pageSize: 10 })
      }
    }

    // 计时ID
    const initSetInterId = ref()
    // 查询集合
    const getInit=()=>{
      getSatisfactionStatistics()
      getNewQuarterlyEarnings()
      getQuarterlyEarningStatistics()
      getCompanyInfos()
      getErrorCountStatisticsList()
      getErrorAlarmCountStatistics()
      getErrorLevelCountStatistics()
      getFaultServiceCenterTabberData()
      errorCountStatistics()
      newCompanyNoticeRecord()
      getProjectTotalStatistics()
    }

    // 关闭计时
    const closeTimeInit=()=>{
      clearInterval(nsetInterval.value)
      clearInterval(sfactionStatisticsDataNsetInterval.value)
      clearInterval(quarterlyEarningStatisticsInterval.value)
      clearInterval(errorLevelCountStatisticsInterval.value)
      clearInterval(errorCountStatisticsListInterval.value)
    }

    // 计时查询
    const getSetInterval=()=>{
      // 计时
      const mine = 3
      const second = 60
      const ms = 1000
      const time = mine*second*ms

      initSetInterId.value = setInterval(()=>{
        closeTimeInit()
        getInit()
      },time)

    }

    /* 结束 数据图表 **********************************/
    onMounted(async () => {
      // 集合查询
      getInit()
      // 计时查询
      getSetInterval()
      // setTabIndex()
    })
    onUnmounted(() => {
      // 清除集合查询
      clearInterval(initSetInterId.value)
      // 清除集合计时
      closeTimeInit()
    })
    return {
      tabberHeight,
      bigTitleBoxImg,
      testOption,
      z4Option,
      bgBStyle,
      mOption,
      z1Option,
      z5Option,
      tabIndex,
      gdImg,
      gjImg,
      gkIconImg,
      jxIconImg,
      pmImg,
      timeImg,
      sfactionStatisticsData,
      fetchNewQuarterlyEarningsData,
      quarterlyEarningStatisticsData,
      errorCountStatisticsListData,
      companyInfosData,
      companyInfosDataIndex,
      errorAlarmCountStatisticsData,
      errorLevelCountStatisticsData,
      deviceErrorRecordsData,
      deviceErrorRecordsDataHeared,
      errorCountStatisticsData,
      errorCountStatisticsReactive,
      onLoad,
      newCompanyNoticeRecordData,
      mapBtnStyle,
      mapBtnActiveStyle,
      mapIndex,
      sfactionStatisticsDataIndex,
      quarterlyEarningStatisticsIndex,
      errorLevelCountStatisticsIndex,
      errorCountStatisticsListIndex,
      projectTotalStatisticsData,
      goProjectList,
      goEquipmentManageList,
      clickMap,
      isFull
    }
  }
}
</script>

<style lang="scss">
.el-scrollbar__view {
  height: 100%;
}
.echarts-view {
  @extend %params-global;
  box-sizing: border-box;
  height: 100%;
  .el-card {
    height: 100%;
  }
  .e-chart-box {
    .el-card__body {
      padding: 0 !important;
      height: 100%;
    }
    &-column {
      display: flex;
      // background-color: #12161f;
      > div {
        flex: 1;
        // width: 25%;
        // background-color: red;
        &:nth-child(2) {
          flex: 2;
          // width: 50%;
          // background-color: blue;
        }
        .big-title-box {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          height: 70px;
          background: {
            size: contain;
            size: 80% 90%;
            position: 50% 0;
            repeat: no-repeat;
          }
          font-size: 34px;
          font-weight: 700;
          letter-spacing: 20px;
        }
      }
      .left-view {
        padding: 5px {
          // box-sizing: border-box;
          right: 0;
        }
        display: flex;
        flex-direction: column;
        > div {
          // padding: 5px;
          margin: 5px;
          flex: 1;
          // width: 100%;
          // height: 100%;
        }
        &-notices {
          margin-top: 30px;
          .notice-notice {
            height: 200px;
          }
        }
      }
      .center-view {
        display: flex;
        flex-direction: column;
        padding-bottom: 5px;
        > div {
          padding: 5px;
          &:nth-child(1) {
            padding: 0;
            // margin-bottom: 20px;
          }
          &:nth-child(3) {
            flex: 2.5;
            // width: 100%;
            // height: calc((100% - 50px) / 0.7);
          }
          &:nth-child(4) {
            flex: 0.5;
            // width: 100%;
            // height: calc((100% - 50px) / 2.3);
          }
        }
        &-item {
          // height: 171px;
          padding: 0 5px;
          display: flex;
          flex-wrap: wrap;
          overflow-x: hidden;
        }
        &-count {
          margin-top: 5px !important;
          > div {
            cursor: pointer;
            span {
              background-color: transparent !important;
              font-size: 32px !important;
              &:last-child {
                font-size: 16px !important;
                color: #b6d7ff;
              }
            }
          }
        }
      }
      .right-view {
        width: 445px;
        padding: 5px {
          left: 0;
        }
        display: flex;
        flex-direction: column;
        > div {
          padding: 5px;
          &:first-child {
            flex: 1.8;
            // width: 100%;
            // height: calc(100% / 1.2);
          }
          &:last-child {
            flex: 1.2;
            // width: 100%;
            // height: calc(100% / 1.8);
          }
        }
        &-first {
          display: flex;
          flex-direction: column;
          height: 100%;
          &-count {
            margin-top: 25px;
            display: flex;
            color: #fff;
            > div {
              width: 100%;
              justify-content: space-between;
              display: flex;
              flex-direction: column;
              align-items: center;
              > span {
                color: #e6004d;
                padding: 2px 10px;
                font-weight: bold;
                font-size: 16px;
                &:first-child {
                  margin-bottom: 2px;
                  font-size: 12px;
                  border-radius: 2px;
                  background: #e6004d;
                  color: #fff;
                }
              }
              &:last-child {
                > span {
                  color: #8aa7f0;
                  &:first-child {
                    background: #8aa7f0;
                    color: #fff;
                  }
                }
              }
            }
          }
          &-body {
            flex: 1;
            display: flex;
            flex-direction: column;
            &-btn {
              display: flex;
              justify-content: space-around;
              margin: 5px 0;
              border-top: 1px solid #2c3657;
              &-div {
                color: #fff;
                flex: 1;
                // background-color: red;
              }
            }
          }
        }
        &-last {
          &-countbox {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          &-count {
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
          }
        }
      }
    }
    .notice-notice {
      height: 160px;
      word-break: break-all;
      overflow: hidden;
      padding: 10px;
    }
  }
}
.map-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-body {
    flex: 1;
  }
  &-btn {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: 0;
    > div {
      width: 92.2px;
      height: 28.4px;
      line-height: 28.4px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      background-size: 100% 100% !important;
    }
  }
}
</style>
