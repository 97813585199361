<template>
  <div class="general-situation">
    <div class="general-situation-title" v-if="showTitle">{{ Ntitle }}</div>
    <div class="general-situation-header" v-if="showHeader">
      <span v-for="item in header" :key="item">{{ item.name }}</span>
    </div>
    <div class="general-situation-body" v-if="showBody">
      <vue-seamless-scroll
        :data="data"
        :class-option="{
          limitMoveNum: 1,
          step: 0.4,
          hoverStop: true
        }"
        :style="{ height: height }"
      >
        <div
          :style="{ width: '100%', marginBottom }"
          v-infinite-scroll="load"
          class="infinite-list"
        >
          <div
            class="general-situation-body-row infinite-list-item"
            v-for="item in data"
            :key="item"
          > 
            <span v-for="v in header" :key="v" :title="item[v.prop]" :style="{color:companyColors[item[v.prop]]}">{{ item[v.prop] }}</span>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, watch } from '@vue/runtime-core'
import vueSeamlessScroll from 'vue-seamless-scroll/src'
export default {
  name: 'GeneralSituation',
  components: {
    vueSeamlessScroll
  },
  props: {
    title: {
      type: String,
      default: '当季'
    },
    height: {
      type: String,
      default: '100px'
    },
    marginBottom: {
      type: String,
      default: '100px'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showBody: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    header: {
      type: Array,
      default: () => [
        { name: '阿米巴', prop: 'companyName' },
        { name: '合同总额(万)', prop: 'contractAmount' },
        { name: '人数', prop: 'userCount' },
        { name: '收入(万)', prop: 'incomeAmount' },
        { name: '成本(万)', prop: 'costAmount' },
        { name: '利润(万)', prop: 'profitAmount' }
      ]
    },
    data: {
      type: Array,
      required: true,
      default: () => []
    }
    // typeIndex: {
    //   type: Array,
    //   required: true,
    //   default: () => []
    // }
  },
  setup(props, context) {
    const Ntitle = ref()
    const dataOne = computed(() => props.data)
    watch(
      () => dataOne.value,
      newvalue => {
        Ntitle.value =
          newvalue.length > 0 ? `${newvalue[0].year} 第${newvalue[0].quarter}季度` : props.title
      }
    )
    const load = () => {
      context.emit('on-load')
    }

    // 故障等级颜色
		const companyColors = {
			'轻微':'#435CFE',
			'一般':'#34B340',
			'严重':'#FFA903',
			'灾难':'#FF6353'
		}

    return { Ntitle, load,companyColors }
  }
}
</script>

<style lang="scss" scoped>
.general-situation {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  > div {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &-title {
    justify-content: flex-end;
  }
  &-header {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      flex: 1;
      // max-width: 20%;
      text-align: center;
      display: inline-block;
    }
  }
  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > div {
      width: 100%;
      overflow: hidden;
    }
    &-row {
      &:nth-child(2n) {
        background: linear-gradient(to right, transparent, #333f5a, #333f5a, transparent);
      }
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 25px;
      line-height: 25px;
      align-items: center;
      text-align: center;
      > span {
        flex: 1;
        text-align: center;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .infinite-list::-webkit-scrollbar-corner,
  .infinite-list::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
</style>
